.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



.m-background {
  position: relative;
  width: 100%;
  /* background-image: url('../src/assets/landing.png'); */
  background-size: cover;
  /* background-position: center; */
  background-position: right;
  /* filter: contrast(3) saturate(1); */
  min-height: calc(100vh - 52px);

  /* position: absolute;
  top: 0px;
  z-index: -1; */
  /* min-height: 100%; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-block: 10px;
}

.m-section {
  /* height: calc(100vh - 52px); */
}