@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #dff8d0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: bonkerFont;
  src: url("./assets/font/PressStart2P.ttf");
}

* {
  font-family: bonkerFont;
  font-weight: 100;

}

.text-color1 {
  color: #c8fbe1;
}

.text-color2 {
  color: #94cd84;

}

.text-color3 {
  color: #ff9200;

}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
}
::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 8px;
}
::-webkit-scrollbar-thumb:hover {
  background: #888;
}
